import {
  CATEGORY_URL,
  PACKAGE_URL,
  PRODUCT_URL,
  SECTION_URL,
  SHORTLIST_PACKAGE_URL,
  VENDOR_URL,
  VENUE_URL,
} from './urls'

export const MERAGI_GOLD = '#d5a969'
export const MERAGI_PURPLE = '#6A30C3'

export const MERAGI_VENDOR_ID = 1
export const BANGALORE_REGION_ID = 1

export const BOOLEAN = 'Boolean'
export const SELECT = 'Select'
export const MULTI_SELECT = 'Multi Select'
export const COLOR = 'Color'
export const IMAGE = 'Image'
export const INTEGER = 'Integer'

export const REQUIRES_OPTIONS = [SELECT, MULTI_SELECT, COLOR]
export const MULTI_OPTIONS = [MULTI_SELECT, COLOR]

// Product form constants
export const SOLUTION = 'Solution'
export const BUNDLE = 'Bundle'
export const DEFAULT = 'Default'

export const REQUIRES_ITEMS = [SOLUTION, BUNDLE]

export const FLORAL = 'FLORAL'
export const FABRIC = 'FABRIC'
export const STRUCTURE = 'STRUCTURE'
export const PRODUCT = 'PRODUCT'

// product Ownership
export const INTERNAL = 'Internal'

// product Stage
export const DRAFT = 'Draft'

// payout status
export const REJECTED = 'rejected'

export const PERMISSIONS = [
  'access_inventory',
  'collect_payment',
  'add_custom_amount',
  'add_custom_payment',
  'select_rfq_vendor',
  'read_staff',
  'write_staff',
  'write_catalog',
  // package
  'change_consultant',
  'validate_line_item',
  'finalize_package',
  'approve_change_request',
  // payouts
  'access_payments',
  'access_payouts',
  'pay_vendor',
  'override_exceed',
  // cms
  'change_crm_mis_date',
  'change_crm_lead_stage',
  // vendor
  'can_add_beneficiary',
  // sales
  'can_add_sales_target',
  // sections
  'can_add_package_section',
  'can_update_package_section',
  'can_delete_package_section',
  // delete executed images
  'can_delete_executed_image',
  // Lock shortlist package payout
  'can_lock_payout',
  // can delete contributor to an event
  'can_delete_contributor',
  // Material Tracking, IMS and WMS
  'can_delete_material_status',
  'can_generate_material',
  // Update the Package Managers in RFQ View
  'update_package_manager',
  // update the inhouse toggle from RFQ View
  'change_inhouse',
  // default Django Permissions
  // Material Source
  'add_materialsource',
  'change_materialsource',
  'delete_materialsource',
  'modify_tracking_after_status_creation',
  // LineItemMaterial
  'add_lineitemmaterial',
  'change_lineitemmaterial',
  'delete_lineitemmaterial',
  // Material
  'add_material',
  'change_material',
  'delete_material',
  //Payment
  'change_payment',
  'delete_payment',
  // Booking and BookingService
  'add_booking',
  'change_booking',
  'delete_booking',
]

export const CONTENT_TYPE: { [key: string]: { model: string; name: string } } =
  {
    [SECTION_URL]: { model: 'section', name: 'Section' },
    [CATEGORY_URL]: { model: 'category', name: 'Category' },
    [PRODUCT_URL]: { model: 'product', name: 'Product' },
    [PACKAGE_URL]: { model: 'package', name: 'Package' },
    [SHORTLIST_PACKAGE_URL]: {
      model: 'shortlistpackage',
      name: 'Shortlist Package',
    },
    [VENDOR_URL]: { model: 'vendor', name: 'Vendor' },
    [VENUE_URL]: { model: 'venue', name: 'Venue' },
  }

// stage/status actions
export const CREATE_USER = 'CREATE_USER'

export const availability_options = [
  { label: 'Buy/Rent', value: 'Buy/Rent' },
  { label: 'Inventory', value: 'Inventory' },
  { label: 'Consumable', value: 'Consumable' },
]
