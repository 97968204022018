import { ImageField, useModalForm } from '@refinedev/antd'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import {
  BaseKey,
  UseFormReturnType,
  useDelete,
  useOne,
  useUpdate,
} from '@refinedev/core'
import {
  Col,
  Descriptions,
  Modal,
  Row,
  Select,
  Statistic,
  Table,
  Tabs,
  Typography,
  Button,
  Empty,
  Card,
  InputNumber
} from 'antd'
import { PriceField } from 'components/field'
import { IPackageDetail, IShortlistPackage } from 'interfaces/shortlistPackage'
import React, { useContext, useEffect, useState } from 'react'
import {
  SESSION_URL,
  SHORTLIST_PACKAGE_DETAILS_URL,
  SHORTLIST_PACKAGE_URL,
  VENUE_SUGGESTION_URL,
} from 'urls'
import { EditPackageContext, EditPackageContextType } from './Edit'
import { CreatePackageDetails, PackageDetailsDetail } from './packageDetails'
import './styles.less'
import { CreateSession } from 'pages/catering/session/Create'
import { ISession } from 'interfaces/catering'
import { SessionDetail } from 'pages/catering/session/SessionDetail'
import { IVenueSuggestion } from 'interfaces/crm'
import AddVenueSuggestion from 'pages/crm/lead/components/venueRequirementComponents/AddVenueSuggestion'

const { Title, Text } = Typography

export const ShortlistPackageDetail: React.FC<{
  useFormProps: UseFormReturnType<IShortlistPackage>
}> = ({ useFormProps }) => {
  const packageData: IShortlistPackage | undefined =
    useFormProps.queryResult?.data?.data
  const refetch = useFormProps.queryResult?.refetch
  const [activeKey, setActiveKey] = useState<number>(
    packageData?.package_details?.length
      ? packageData?.package_details[0].id
      : 0,
  )
  const { editPackageStatus } = useContext(
    EditPackageContext,
  ) as EditPackageContextType

  const [packageServiceName, setPackageServiceName] = useState<string>(
    packageData?.service_package.service.name ?? "",
  )
  const { mutate } = useUpdate()
  const { mutate: deleteMutate } = useDelete()

  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    show: createModalShow,
  } = useModalForm<IPackageDetail>({
    action: 'create',
    resource: 'package_tool/shortlist_package/package_details',
    redirect: false,
    onMutationSuccess: (data) => {
      refetch?.()
      setActiveKey(data.data.id)
    },
  })

  const {
    modalProps: createSessionModalProps,
    formProps: createSessionFormProps,
    show: createSessionModalShow,
  } = useModalForm<ISession>({
    action: 'create',
    resource: SESSION_URL,
    redirect: false,
    onMutationSuccess: (data) => {
      refetch?.()
      setActiveKey(data.data.id as number)
    },
  })

  const { data: stageData } = useOne<any[]>({
    resource: SHORTLIST_PACKAGE_URL,
    id: 'stage_list',
  })

  const handleOnChangeDetails = (value: string | number, key: string) => {
    useFormProps.id &&
      mutate(
        {
          resource: 'package_tool/shortlist_packages',
          values: {
            [key]: value,
          },
          id: useFormProps.id,
        },
        {
          onError: (error, variables, context) => {
            // An error occurred!
          },
          onSuccess: (data, variables, context) => {
            refetch?.()
          },
        },
      )
  }

  const handlePackageStageChange = (stage: string) => {
    mutate(
      {
        resource: SHORTLIST_PACKAGE_URL,
        id: `${packageData?.id}/set_package_stage`,
        values: {
          stage: stage,
        },
      },
      {
        onSuccess: (data) => {
          refetch?.()
        },
      },
    )
  }

  const PackageInfo: React.FC<{
    data: IShortlistPackage
  }> = ({ data }) => {
    return (
      <div>
        <Row>
          <Col span={18}>
            <Row>
              <Col span={10}>
                <Descriptions>
                  <Descriptions.Item label="Customer">
                    {packageData?.customer
                      ? `${packageData?.customer.first_name} ${packageData?.customer.last_name} - ${packageData?.customer.username}`
                      : 'Not Assigned'}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              <Col span={8} offset={4}>
                <Descriptions>
                  <Descriptions.Item label="Consultant">
                    {packageData?.consultant
                      ? `${packageData?.consultant.first_name} ${packageData?.consultant.last_name} - ${packageData?.consultant.username}`
                      : 'Not Assigned'}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
            <Row>
              <Col span={10}>
                <Descriptions>
                  <Descriptions.Item label="Project">
                    {packageData?.event.name}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              <Col span={8} offset={4}>
                <Descriptions>
                  <Descriptions.Item label="Project Manager">
                    {packageData?.project_manager
                      ? `${packageData?.project_manager.first_name} ${packageData?.project_manager.last_name} - ${packageData?.project_manager.username}`
                      : 'Not Assigned'}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
            <Row>
              <Col span={10}>
                <Descriptions>
                  <Descriptions.Item label="Package Name">
                    <Text
                      editable={ !packageData?.is_cancelled && {
                        onChange: (value) =>
                          handleOnChangeDetails(value, 'name'),
                      }}>
                      {packageData?.name}
                    </Text>
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
            <Row>
              <Col span={10}>
                <Descriptions>
                  <Descriptions.Item label="Service">
                    <Text>{packageData?.service_package.service.name}</Text>
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              <Col span={8} offset={4}>
                <Descriptions>
                  <Descriptions.Item label="Stage">
                    {!packageData?.is_cancelled && <Select
                      onChange={handlePackageStageChange}
                      placeholder="Stage"
                      value={packageData?.stages?.[0]?.stage}
                      popupMatchSelectWidth={false}
                      options={stageData?.data.map((t: any) => ({
                        label: t,
                        value: t,
                      }))}
                    />}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
          </Col>
          <Col span={6}>
            <Row>
              <Col span={12}>
                <Statistic
                  title={<Title level={5}>Discount</Title>}
                  formatter={(value) => {
                    return (
                      <PriceField
                        value={
                          packageData?.discount_amount
                            ? packageData?.discount_amount
                            : 0
                        }
                        style={{ fontSize: '20px' }}
                      />
                    )
                  }}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title={<Title level={5}>Package Total</Title>}
                  formatter={(value) => {
                    return (
                      <PriceField
                        value={packageData?.price ? packageData?.price : 0}
                        style={{ fontSize: '20px' }}
                      />
                    )
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    )
  }

  const { mutate: destroy } = useDelete()

  const onEdit = (
    targetKey: React.MouseEvent | React.KeyboardEvent | string,
    action: 'add' | 'remove',
  ) => {
    if (action === 'add') {
      if (packageServiceName == "Catering") {
        createSessionModalShow()
      } else {
        createModalShow()
      }
    } else if (action === 'remove') {
      Modal.confirm({
        title: 'Do you Want to delete this event/session?',
        icon: <Icons.ExclamationCircleFilled />,
        content: 'All the items for this event will be deleted',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        width: '10px',
        onOk() {
          destroy(
            {
              resource: packageServiceName == 'Catering' 
                ? SESSION_URL
                : SHORTLIST_PACKAGE_DETAILS_URL,
              id: targetKey as string,
            },
            { onSuccess: () => refetch && refetch() },
          )
        },
      })
    }
  }

  const [editingRecordId, setEditingRecordId] = useState<BaseKey>();
  const [updatedPrice, setUpdatedPrice] = useState<number>();
  const { mutate: update } = useUpdate()

  const renderVenueDetailsUI = () => {   
    const handleSave = (recordId: BaseKey) => {
      setEditingRecordId(undefined);
      update(
        {
          resource: VENUE_SUGGESTION_URL,
          id: recordId,
          values: {
            id: recordId,
            quoted_price: updatedPrice,
          },
        },
        {
          onSuccess: () => {
            refetch && refetch()
          },
        },
      )
    };
  
    const handleClose = () => {
      setEditingRecordId(undefined);
    };
  
    const handleDelete = (recordId: BaseKey) => {
      Modal.confirm({
        title: 'Are you sure you want to delete this suggestion?',
        onOk: () => {
          deleteMutate(
            {
              resource: VENUE_SUGGESTION_URL,
              id: recordId,
            },
            {
              onSuccess: () => {
                refetch && refetch();
              },
            },
          );
        },
      });
    };
  
    return (
      <div style={{ margin: '10px' }}>
        {packageData && (
          <>
            {packageData?.durations?.length > 0 && (<>
              <Row gutter={[16, 16]}>
                {packageData.durations[0].venue_suggestions.map((record) => (
                  <Col xs={24} sm={12} md={8} lg={6} key={record.id}>
                    <Card
                      hoverable
                      style={{ borderRadius: '8px', overflow: 'hidden' }}
                      cover={
                        record.venue.logo ? (
                          <ImageField
                            value={record.venue.logo}
                            title={record.venue.name}
                            alt={' ' + record.venue.name + ' Image'}
                            style={{
                              width: '100%',
                              height: '200px',
                              objectFit: 'contain',
                              padding: '10px',
                              backgroundColor: '#f5f5f5',
                            }}
                          />
                        ) : (
                          <div
                            style={{
                              width: '100%',
                              height: '200px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              backgroundColor: '#f5f5f5',
                            }}
                          >
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Image" />
                          </div>
                        )
                      }
                    >
                      <Card.Meta
                        title={<h3 style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>{record.venue.name}</h3>}
                        description={
                          <>
                            <p style={{ margin: '10px 0' }}>
                              <strong>Status:</strong> {record.statuses && record.statuses[0].status}
                            </p>
                            <p style={{ margin: '10px 0' }}>
                              <strong>Quoted Price: </strong> 
                              {editingRecordId === record.id ? (
                                <>
                                  <InputNumber
                                    value={ record.quoted_price }
                                    onChange={(value) => setUpdatedPrice(value ?? 0)}
                                    style={{ width: '70%' }}
                                  />
                                  <Button
                                    icon={<Icons.SaveOutlined />}
                                    style={{ marginLeft: '8px' }}
                                    onClick={() => handleSave(record.id)}
                                  />
                                  <Button
                                    icon={<Icons.CloseCircleOutlined />}
                                    style={{ marginLeft: '8px' }}
                                    onClick={() => handleClose()}
                                  />
                                </>
                              ) : (
                                <>
                                  ₹{record?.quoted_price?.toLocaleString('en-IN') ?? "--"}
                                  <Button
                                    icon={<Icons.EditOutlined />}
                                    type="link"
                                    style={{ marginLeft: '8px' }}
                                    onClick={() => setEditingRecordId(record.id)}
                                  >
                                    Edit
                                  </Button>
                                </>
                              )}
                            </p>
                            <p style={{ margin: '10px 0' }}>
                              <strong>Booked Price:</strong> ₹{record?.booked_price?.toLocaleString('en-IN') ?? "--"}
                            </p>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <Button
                                type="primary"
                                style={{ backgroundColor: '#6C63FF', borderColor: '#6C63FF' }}
                                onClick={() => {
                                  window.open(`/cms/venue-showcase/detail/${record.venue.id}`);
                                }}
                              >
                                View Details
                              </Button>
                              {!record.is_shared_with_venue && (
                                <Button
                                  icon={<Icons.DeleteOutlined />}
                                  onClick={() => handleDelete(record.id)}
                                >
                                  Delete
                                </Button>
                              )}
                            </div>
                          </>
                        }
                      />
                    </Card>
                  </Col>
                ))}
              </Row>
            </>)}
            <br />
            <br />
            <AddVenueSuggestion
              packageId={packageData.id}
              refetch={refetch}
            />
            
          </>
        )}
      </div>
    );
  };
  

  const renderCateringDetailsUI = () => {
    return <div>
        <Tabs
          type="editable-card"
          onEdit={onEdit}
          activeKey={activeKey.toString()}
          onChange={(key) => {
            setActiveKey(Number(key))
          }}
          hideAdd={!editPackageStatus || packageData?.is_cancelled}
          items={
            packageData?.sessions?.map((sessionDetail) => ({
                  label: sessionDetail.name,
                  key: sessionDetail.id.toString(),
                  close: <Icons.DeleteOutlined />,
                  closable: !packageData?.is_finalized && !packageData.is_cancelled,
                  children: (
                    <SessionDetail
                      sessionId={sessionDetail.id}
                      key={sessionDetail.id}
                      refetch={refetch}
                      package={packageData.id}
                    />
                  ),
                }))
          }
        />
        {packageData && 
          ( 
            <CreateSession
              modalProps={createSessionModalProps}
              formProps={createSessionFormProps}
              shortlistPackageId={packageData?.id}
            />
        )}
      </div>
  };

  const renderPackageDetailsUI = () => {
    return <div>
        <Tabs
          type="editable-card"
          onEdit={onEdit}
          activeKey={activeKey.toString()}
          onChange={(key) => {
            setActiveKey(Number(key))
          }}
          hideAdd={!editPackageStatus || packageData?.is_cancelled}
          items={
            packageData?.package_details?.map((packageDetail) => ({
                  label: packageDetail.section.name,
                  key: packageDetail!.id.toString(),
                  closeIcon: <Icons.DeleteOutlined />,
                  closable: !packageData.is_finalized && !packageData.is_cancelled,
                  children: (
                    <PackageDetailsDetail
                      package_detail_id={packageDetail.id}
                      key={packageDetail.id}
                      refetch={refetch}
                      package={packageData?.id}
                    />
                  ),
                }))
          }
        />
        {packageData && (
          (
              <CreatePackageDetails
                modalProps={createModalProps}
                formProps={createFormProps}
                shortlistPackageId={packageData?.id}
                projectId={packageData?.event?.id}
              />
            )
        )}
      </div>
  };

  const renderServiceDetailsUI = () => {
    if (packageData) {
      if (packageServiceName === 'Venue') {
        if (packageData.package_details?.length) {
          return renderPackageDetailsUI();
        } else {
          return renderVenueDetailsUI();
        }
      } else if (packageServiceName === 'Catering') {
        return renderCateringDetailsUI();
      } else {
        return renderPackageDetailsUI();
      }
    }
  };

  useEffect(() => {
    if (packageData) {
        setPackageServiceName(packageData?.service_package.service.name)
    }
  }, [packageData])
  return (
    <>
      <div>{packageData && <PackageInfo data={packageData} />}</div>
      {renderServiceDetailsUI()}
    </>
  )
}
