import { Form, FormProps, Input } from 'antd'
import { BaseKey, file2Base64 } from '@refinedev/core'
import { SingleImageUpload } from 'components/input'

export const ServiceForm: React.FC<{
  formProps: FormProps
  id?: BaseKey
  queryResult?: any
}> = ({ formProps, id, queryResult }) => {
  const onFinish = async (values: any) => {
    let serviceImage = ''
    const { image } = values

    // handle Profile Image
    delete values.profile_image
    if (image) {
      const file = image[image?.length - 1]

      if (file.originFileObj) {
        const base64String = await file2Base64(file)
        serviceImage = base64String
      }
    }

    const finalResponse = { ...values }
    if (serviceImage.length) finalResponse['image'] = serviceImage

    return formProps.onFinish && formProps.onFinish(finalResponse)
  }

  return (
    <Form {...formProps} layout="vertical" onFinish={onFinish}>
      <Form.Item name="name" label="Name">
        <Input />
      </Form.Item>
      <SingleImageUpload
        label="Image"
        name="image"
        buttonText="Service Image"
        initialImageUrl={queryResult?.data?.data?.image}
      />
    </Form>
  )
}
