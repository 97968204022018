import {
  Card,
  Collapse,
  Form,
  FormProps,
  Input,
  InputNumber,
  Select,
  Switch,
} from 'antd'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { DraggableMultiImageUpload } from 'components/input/DraggableMultiImageUpload'
import { UploadFile } from 'antd'
import {
  COLOR_URL,
  FABRIC_TYPE_URL,
  FLOWER_URL,
  FOLIAGE_TYPE_URL,
  MATERIAL_URL,
  PRICING_ITEM_URL,
  PRODUCT_URL,
  REGION_URL,
} from 'urls'
import { useState, useEffect } from 'react'
import { IMaterialCategoryOption, IMaterialImage } from 'interfaces/material'
import { file2Base64 } from '@refinedev/core'
import { useSelect } from '@refinedev/antd'
import { REGION_KEY } from 'authProvider'

export const MaterialForm: React.FC<{
  formProps: FormProps
  queryResult?: any
  disabled?: boolean
}> = ({ formProps, queryResult, disabled = false }) => {
  const [imageFileList, setImageFileList] = useState<UploadFile[]>([])

  // build the images list in the format required by the antd form
  useEffect(() => {
    const existingFiles: UploadFile[] = []
    queryResult?.data?.data?.images?.map((image: IMaterialImage) =>
      existingFiles.push({
        uid: image.id.toString(),
        name: 'test.png',
        url: image.image,
        thumbUrl: image.image,
        status: 'done',
      }),
    )
    setImageFileList(existingFiles)
  }, [queryResult])

  const onFinish = async (values: any) => {
    const imageFiles: any[] = []

    for (let i = 0; i < imageFileList?.length; i++) {
      if (imageFileList[i].originFileObj) {
        const base64String = await file2Base64(imageFileList[i])
        imageFiles.push({
          image: base64String,
        })
      } else {
        imageFiles.push({
          // @ts-ignore
          id: imageFileList[i].id ?? imageFileList[i].uid,
        })
      }
    }
    if (!values.category) {
      values = { ...values, category: null }
    }
    return (
      formProps.onFinish &&
      formProps.onFinish({
        ...values,
        images: imageFiles,
      })
    )
  }

  const {
    selectProps: materialCategorySelectProps,
    queryResult: materialCategoryData,
  } = useSelect<IMaterialCategoryOption>({
    resource: `${MATERIAL_URL}/material_category_options`,
    optionLabel: 'name',
    optionValue: 'name',
  })

  return (
    <Form
      {...formProps}
      layout="vertical"
      onFinish={onFinish}
      disabled={disabled}>
      <Form.Item label="Name" name="name">
        <Input />
      </Form.Item>
      <Form.Item
        label="Material Category"
        name="category"
        rules={[{ required: true, message: 'Please enter the category' }]}>
        <Select
          {...materialCategorySelectProps}
          allowClear
          showSearch={false}
        />
      </Form.Item>
      <Form.Item label="Product" name="product">
        <SelectWithDefault
          useSelectProps={{ resource: PRODUCT_URL, optionLabel: 'name' }}
        />
      </Form.Item>
      <Form.Item label="Item" name="item">
        <SelectWithDefault
          useSelectProps={{
            resource: PRICING_ITEM_URL,
            optionLabel: 'name',
          }}
        />
      </Form.Item>
      <Form.Item label="Flower" name="flower">
        <SelectWithDefault<any>
          useSelectProps={{ resource: `${FLOWER_URL}?detailed=true` }}
          renderOptions={(flowers) =>
            flowers?.map((flower) => (
              <Select.Option key={flower.id} value={flower.id}>
                {flower?.color?.name} {flower?.flower_type?.name}
              </Select.Option>
            ))
          }
        />
      </Form.Item>
      <Form.Item label="Fabric" name="fabric">
        <SelectWithDefault
          useSelectProps={{
            resource: FABRIC_TYPE_URL,
            optionLabel: 'name',
          }}
        />
      </Form.Item>
      <Form.Item label="Foliage" name="foliage">
        <SelectWithDefault
          useSelectProps={{
            resource: FOLIAGE_TYPE_URL,
            optionLabel: 'name',
          }}
        />
      </Form.Item>
      <Form.Item label="Color" name="color">
        <SelectWithDefault
          useSelectProps={{ resource: COLOR_URL, optionLabel: 'name' }}
        />
      </Form.Item>
      <Form.Item
        label="is Consumable?"
        name="is_consumable"
        valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item
        name="region"
        label="Region"
        required
        initialValue={Number(localStorage.getItem(REGION_KEY))}>
        <SelectWithDefault
          useSelectProps={{
            resource: REGION_URL,
            optionValue: 'id',
            optionLabel: 'name',
          }}
        />
      </Form.Item>
      <Card size="small" title="Add by Default">
        <Form.Item
          label="Should be added by default?"
          name="add_by_default"
          valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item label="Default QTY" name="default_qty">
          <InputNumber />
        </Form.Item>
        <Form.Item label="UOM" name="uom">
          <Input />
        </Form.Item>
      </Card>
      <Collapse>
        <Collapse.Panel key="1" header="Media">
          <DraggableMultiImageUpload
            label="Images"
            name="images"
            files={imageFileList}
            onChange={setImageFileList}
          />
        </Collapse.Panel>
      </Collapse>
    </Form>
  )
}
