import { BaseKey } from '@refinedev/core'
import { ITag } from './attribute'
import { IDuration, IService } from './crm'
import { ICustomer } from './customer'
import { IProduct } from './product'
import { ISection } from './section'
import { IStaff } from './staff'
import { IBeneficiaryAccount, IVendor } from './vendor'
import { IVenue } from './venue'
import { ISession } from './catering'

export interface IShortlistPackage {
  id: string
  name: string
  created_at: Date
  updated_at: Date
  description: string
  price: number
  discount_amount: number
  customer: IUser
  event: IEventName
  is_finalized: boolean
  rfq_locked: boolean
  is_selected: boolean
  is_executed: boolean
  is_link_active: boolean
  is_payout_locked: boolean
  is_individual_price_shown: boolean
  is_inhouse: boolean
  consultant: IStaff
  project_manager: IStaff
  package_details?: IPackageDetail[]
  service_package: IServicePackage
  service: BaseKey | undefined
  stages: IShortlistPackageStage[]
  project_executive: IStaff
  dimantle_manager: IStaff
  sessions: ISession[]
  durations: IDuration[]
  warehouse_executive: IStaff
  planning_executive: IStaff
  procurement_executive: IStaff
  sourcing_executive: IStaff
  dispatch_warehouse: number
  is_cancelled: boolean
  is_template: boolean
  venue?: number[]
  style?: string
}

export interface IShortlistPackageStage {
  id: BaseKey
  stage: string
  package: string
  updated_at: Date
  change_by: number
}

export interface IProjectServicePackage {
  service: IService
  packages: IShortlistPackage[]
}

export interface IServicePackage {
  service: IService
  project: number
  budget: number
  finalized_amount: number
  paid_amout: number
}

export interface IProject {
  id: number
  name: string
  referral_code: string
  rfq_finalized: boolean
}

interface IServiceName {
  id: BaseKey
  name: string
}
export interface IPlan {
  id: BaseKey
  budget: number
  name: string
  max_product_count: number
  order: number
  service: number | IServiceName
}


export interface IEventName {
  id: number
  name: string
}

export interface IUser {
  id: number
  username: string
  first_name?: string
  last_name?: string
}

export interface IBasicSection {
  section__id: string
  section__name: string
}

export interface IDisplayProduct {
  id: number
  images: ILineItemImage[]
  name: string
  has_breakdown: boolean
}

export interface IItem {
  id: string
  product?: IDisplayProduct
  file?: string
  quantity: number
  price: number
  description: string
  specification?: string
  is_included: boolean
  is_validated: boolean
  package: string
  alternates?: IAlternateItem[]
  order: number
  executed_image_itm_count: number
  comments_count?: { count: number; unresolved_count: number }
  reference_images: ILineItemImage[]
  pricing_tool_price: number
}

export interface IAlternateItem {
  id: string
  product: IDisplayProduct
  quantity: number
  price: number
  description: string
}

export interface IStatusUpdate {
  created_at: string
  updated_at: string
  id: string
  item: string
  status: string
}

export interface IItemDetailed {
  id: string
  product: IProduct
  description: string
  specification?: string
  quantity: number
  section: string
  status_updates: IStatusUpdate[]
  file?: string
  reference_images: ILineItemImage[]
}

export interface IShortlistPackageSection {
  section: string
  items: IItem[]
  price: number
}

export interface IShortlistPackageItems {
  package: string
  items: IItemDetailed[]
}

export interface IShortlistPackageItemsDetailed {
  package: IShortlistPackage
  items: IItemDetailed[]
}

export interface ILineItemImage {
  id: string
  image: string
}

export interface IPackageDetail {
  created_at: string
  updated_at: string
  date_time: any
  date_not_decided: boolean
  dismantling_start_date_time: any
  dismantling_date_time: any
  decor_date_time: any
  venue_not_decided: boolean
  spoc_contact: any
  pax: number
  venue: IVenue
  section: ISection
  package: BaseKey
  id: number
  total_price?: number
  items?: IItem[]
  executed_image_pkg_count: number
  notes_count: number
  stages: IPackageDetailsStage[]
  is_alacart: boolean
}

interface IPackageDetailsStage {
  id: BaseKey
  stage: string
  package: string
  updated_at: Date
  change_by: number
}

export interface IPackageDetailsNote {
  id: BaseKey
  package_details: BaseKey
  user: IStaff | BaseKey
  text: string
  created_at: string
}

export interface IExecutedImage {
  id: BaseKey
  package_details: BaseKey
  image: string
  tags?: ITag[]
  project_id?: number
  product_name?: string
  venue_name?: string
  executed_date_time?: string
}

export interface IEventCalendarPackage
  extends Omit<
    IShortlistPackage,
    'customer' | 'consultant' | 'project_manager'
  > {
  customer: ICustomer
  consultant: IStaff
  project_manager: IStaff
}

export interface IEventCalendar
  extends Omit<IPackageDetail, 'section' | 'package' | 'venue'> {
  section: ISection
  package: IEventCalendarPackage
  venue: IVenue
  price: number
  collected: number
}

export interface IPayoutSummaryVendor {
  vendor: IVendor
  total: number
  paid: number
  pending: number
  fund_account?: IBeneficiaryAccount
}

export interface IPayoutSummary {
  service: string
  total: number
  paid?: number
  vendors: IPayoutSummaryVendor[]
}

export interface IValidationComment {
  created_at: string
  updated_at: string
  id: BaseKey
  comment: string
  comment_by: BaseKey | IStaff
  resolved_by?: BaseKey | IStaff
  resolved_at?: string
  line_item: BaseKey
}

export enum ChangeType {
  EDIT = 'Edit',
  CREATE = 'Create',
  DELETE = 'Delete',
}

export interface IChangeTracker {
  id: BaseKey
  created_at: string
  package: BaseKey
  package_detail?: BaseKey
  line_item?: BaseKey
  change?: any
  approved: boolean
  rejected: boolean
  request_by?: BaseKey | IStaff
  approval_by?: BaseKey | IStaff
  approval_at?: string
  product_name: string
  description?: string
  change_type?: ChangeType
}
