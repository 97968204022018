// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import { AndroidFilled, AppleFilled, UserOutlined } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelect } from '@refinedev/antd'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import {
  Layout as AntdLayout,
  Button,
  Dropdown,
  Form,
  Grid,
  Input,
  Menu,
  Modal,
  Select,
} from 'antd'

import {
  useGetIdentity,
  useInvalidate,
  useLogout,
  useModal,
  useRouterContext,
} from '@refinedev/core'
import { useHttpClient } from 'App'
import { FLUTTER_REGION_KEY, REGION_KEY } from 'authProvider'
import { useForm } from 'components/hooks/useForm'
import { IStaff } from 'interfaces/staff'
import React from 'react'
import { Title } from '../title'
import { CHATS_URL } from 'urls'

export const Header: React.FC = () => {
  const { data: user } = useGetIdentity<IStaff>()
  const { mutate: logout } = useLogout()

  const invalidate = useInvalidate()

  const httpClient = useHttpClient()

  const { selectProps: regionSelectProps } = useSelect({
    resource: 'core/regions',
    optionLabel: 'name',

    pagination: {
      mode: 'server',
    },
  })

  const { visible, show, close } = useModal()
  const {
    visible: bugModalVisible,
    show: bugModalShow,
    close: bugModalClose,
  } = useModal()
  const useFormProps = useForm({
    action: 'create',
    resource: 'auth/users/set_password',
    onMutationSuccess: () => {
      close()
      logout()
    },
  })

  const breakpoint = Grid.useBreakpoint()
  var hide = false
  if (breakpoint.lg || breakpoint.md) {
    hide = false
  } else if (breakpoint.sm || breakpoint.xs) {
    hide = true
  }

  const { useHistory } = useRouterContext()
  const history = useHistory()

  const menu = (
    <Menu>
      <Menu.Item
        hidden={!hide}
        onClick={(e) => raiseInvoiceClicked('play_store')}
        title="Download Android App">
        <Icons.AndroidFilled style={{ paddingRight: '10px' }} />
        Download Android App
      </Menu.Item>
      <Menu.Item
        hidden={!hide}
        onClick={(e) => raiseInvoiceClicked('app_store')}
        title="Download iOS App">
        <Icons.AppleFilled style={{ paddingRight: '10px' }} />
        Download iOS App
      </Menu.Item>
      <Menu.Item onClick={() => bugModalShow()}>
        <Icons.BugOutlined style={{ paddingRight: '10px' }} />
        Bug Report
      </Menu.Item>
      <Menu.Item onClick={() => show()}>
        <Icons.KeyOutlined style={{ paddingRight: '10px' }} />
        Reset Password
      </Menu.Item>
      <Menu.Item onClick={() => logout()}>
        <Icons.LogoutOutlined style={{ paddingRight: '10px' }} />
        Logout
      </Menu.Item>
    </Menu>
  )

  return (
    <AntdLayout.Header>
      <Title collapsed={true} />
      <div className="headerToolbar">
        {window.location.pathname !== `/${CHATS_URL}` && (
          <Button
            type="primary"
            size="middle"
            icon={<Icons.MessageOutlined />}
            onClick={() => {
              history.push(CHATS_URL)
            }}
            title={'Chats'}>
            Chat
          </Button>
        )}
        <Button
          hidden={hide}
          icon={<AndroidFilled />}
          onClick={(e) => raiseInvoiceClicked('play_store')}
          title="Download Android App"
        />
        <Button
          hidden={hide}
          icon={<AppleFilled />}
          onClick={(e) => raiseInvoiceClicked('app_store')}
          title="Download iOS App"
        />
        <Select
          {...regionSelectProps}
          // @ts-ignore
          defaultValue={Number(localStorage.getItem(REGION_KEY))}
          className="regionDropdown"
          showSearch={false}
          // @ts-ignore
          onSelect={(value: string) => {
            localStorage.setItem(REGION_KEY, value)
            localStorage.setItem(FLUTTER_REGION_KEY, value)
            invalidate({ invalidates: ['all'] })
            httpClient.interceptors.request.use((config) => {
              if (config.headers) (config.headers as any)['X-Region'] = value
              return config
            })
            window.location.reload()
          }}
          popupMatchSelectWidth={false}
        />
        <Dropdown
          placement="topRight"
          arrow={{ pointAtCenter: true }}
          overlay={menu}
          trigger={['click']}>
          <Button icon={<UserOutlined size={10} />} title="Click to Open">
            {user?.first_name}
          </Button>
        </Dropdown>
      </div>
      <Modal
        title="Change Password"
        visible={visible}
        onCancel={close}
        destroyOnClose
        onOk={() => useFormProps.form.submit()}>
        <Form {...useFormProps} id={undefined} layout="vertical">
          <Form.Item
            name="current_password"
            label="Current Password"
            rules={[{ required: true }]}>
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="new_password"
            label="New Password"
            rules={[{ required: true }]}>
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Bug Report"
        open={bugModalVisible}
        closable
        onOk={bugModalClose}
        onCancel={bugModalClose}
        destroyOnClose>
        <iframe
          style={{ border: 'none' }}
          src="https://docs.google.com/forms/d/e/1FAIpQLSfW6kiMPk_KxDh4xz5Ci2tW0UYip8aRwcjKvMXEjkvEdcXOZg/viewform?embedded=true"
          width="1160"
          height="600">
          Loading…
        </iframe>
      </Modal>
    </AntdLayout.Header>
  )
}
function raiseInvoiceClicked(store: String) {
  var playStoreUrl =
    'https://play.google.com/store/apps/details?id=com.meragi.internal'
  var appStoreUrl = 'https://apps.apple.com/us/app/meragi-studio/id6444104659'
  switch (store) {
    case 'play_store':
      window.open(playStoreUrl, '_blank')
      break
    case 'app_store':
      window.open(appStoreUrl, '_blank')
      break
  }
}
