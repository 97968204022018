import { BaseKey } from '@refinedev/core'

export enum AreaType {
  NORTH = 'North',
  SOUTH = 'South',
  EAST = 'East',
  WEST = 'West',
  CENTRAL = 'Central',
}

export enum OverallPackageType {
  VALUE = 'Value',
  DELIGHT = 'Delight',
  PRIME = 'Prime',
  LUXURY = 'Luxury',
}

export enum SpaceType {
  POOL_SIDE = "Pool Side",
  OUTDOOR = "Outdoor",
  INDOOR = "Indoor",
}

export enum Style {
  CONTEMPORARY = "Contemporary",
  TRADITIONAL = "Traditional",
  FUSION = "Fusion",
}

export interface IVenue {
  id: number
  name: string
  slug: string
  logo: string
  description?: string
  is_personal: boolean
  is_empanelled: boolean
  is_partnership: boolean
  is_enrolled: boolean
  has_accommodation: boolean
  has_catering: boolean
  line_1: string
  line_2: string
  city: string
  state: string
  pincode: string
  spoc_name: string | null
  spoc_contact: string | null
  google_map_link: string | null
  map_image?: string
  is_important: boolean
  discount: number
  seating_capacity: number | null
  floating_capacity: number | null
  accommodation: IAccommodation[]
  catering: ICatering[]
  parking_available: boolean
  parking_car_count: number | null
  valet_available: boolean
  area_type: AreaType
  venue_type: OverallPackageType
  catering_in_house: boolean
  catering_out_house: boolean
  members: BaseKey[]
}

export interface IAccommodation {
  id: BaseKey
  name: string
  image: string
  number_of_rooms: number | null
  help_text: string | null
  is_included_in_rental: boolean
  price: number
}

export enum MealScheduleType {
  BREAKFAST = 'Breakfast',
  LUNCH = 'Lunch',
  HIGH_TEA = 'High Tea',
  DINNER = 'Dinner',
}

export interface ICatering {
  id: BaseKey
  name: string
  image: string
  thumbnail: string
  price: number
  meal_schedule: MealScheduleType
  veg_price: number
  non_veg_price: number
}

export enum MediaType {
  IMAGE = 'Image',
  VIDEO = 'Video',
  DOCUMENT = 'Document',
}

export interface IMediaSection {
  id: BaseKey
  venue: BaseKey
  media_type: MediaType
  name: string
  money_shot: boolean
  order: number
  section_medias: IVenueMedia[]
}

export interface IVenueMedia {
  id: BaseKey
  media_section: BaseKey
  media?: string
  link?: string
}

export enum LocationType {
  CITY = 'City',
  AIRPORT = 'Airport',
  RAILWAY_STATION = 'Railway Station',
  BUS_STATION = 'Bus Station',
  OTHERS = 'Others',
}

export interface IKeyLocation {
  id: BaseKey
  venue: BaseKey
  time: number
  distance: number
  location_type: LocationType
  location_name: string
}

export interface IVenueRental {
  id: BaseKey
  venue: BaseKey
  name?: string
  from_hr: string | null
  to_hr: string | null
  price: number | null
}

export interface INearbyAccommodation {
  id: BaseKey
  venue: BaseKey
  venue_option?: BaseKey | IVenue
  venue_option_text?: string
  price: number | null
  price_on_enquiry: boolean
  time?: number
  distance?: number
}

export interface IOverallPackage {
  id: BaseKey
  venue: BaseKey
  package_type: OverallPackageType
  thumbnail?: string
  total: number | null
  subheading: string
  venue_price: number | null
  decor_price: number | null
  catering_price: number | null
  photography_price: number | null
}

export enum ServicePackageType {
  VALUE = 'Value',
  DELIGHT = 'Delight',
  LUXURY = 'Luxury',
}

export interface IServicePackage {
  id: number
  venue: BaseKey
  service?: BaseKey
  package_type: ServicePackageType
  link?: string
  package?: BaseKey
}

export interface IVenueAvailability {
  id: BaseKey
  created_at: string
  updated_at: string
  venue: BaseKey | IVenue
  date: string
  is_available: boolean
  price: number
}

export interface SpaceAvailability {
  id: BaseKey
  venue_availability: BaseKey
  space: BaseKey
  is_available: boolean
  price: number
}

export interface GroupedVenueAvailability {
  id: BaseKey
  name: string
  availabilities: IVenueAvailability[]
}

export interface IPartialVenueAvailability {
  id: string
  name: string
  is_available: boolean
  price: number
}

export interface IDateGroupedVenueAvailability {
  date: string
  venues: IPartialVenueAvailability[]
}
