import { useModal } from '@refinedev/antd'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import { Modal, Tooltip, Spin, Descriptions } from 'antd'
import { BaseKey, useOne } from '@refinedev/core'
import { ILeadQuizData, IQuizGuestPaxCount } from 'interfaces/crm'
import React from 'react'
import { LEAD_URL } from 'urls'
import Title from 'antd/lib/typography/Title'
import { QuestionProps } from './Question'

const QuizPackageDetails: React.FC<{
  packageDetail?: string
  quizPackageDetail?: {
    event: string
    guests: string
    day: string
  }[]
}> = ({ packageDetail, quizPackageDetail }) => {
  return (
    <div>
      <Title level={5}>Event Details - {packageDetail}</Title>
      {quizPackageDetail?.map((detail, index) => (
        <Descriptions key={index} column={4}>
          <>
            <Descriptions.Item label={'Day'}>{detail.day}</Descriptions.Item>
            <Descriptions.Item label={'Meal'}>{detail.event}</Descriptions.Item>
            <Descriptions.Item label={'Guests'}>
              {detail.guests}
            </Descriptions.Item>
          </>
        </Descriptions>
      ))}
    </div>
  )
}

export const QuizDataModal: React.FC<{
  leadId: BaseKey
  leadName: string
}> = ({ leadId, leadName }) => {
  const { show, modalProps } = useModal()
  const { data: leadQuizData, isFetching: leadQuizLoading } =
    useOne<ILeadQuizData>({
      resource: LEAD_URL,
      id: `${leadId}/get_quiz_data`,
      queryOptions: {
        enabled: modalProps.open,
      },
    })

  return (
    <>
      <Tooltip title="Quiz filled by customer">
        <Icons.StarFilled style={{ color: 'gold' }} onClick={show} />
      </Tooltip>
      <Modal {...modalProps} title={`Quiz Data`} footer={null}>
        {leadQuizLoading ? (
          <>
            <Spin />
          </>
        ) : (
          <>
            <Descriptions layout={'horizontal'}>
              <Descriptions.Item label={'Lead'}>{leadName}</Descriptions.Item>
              <Descriptions.Item label={'Phone'}>
                {leadQuizData?.data?.phone ?? ''}
              </Descriptions.Item>
              <Descriptions.Item label={'Venue'}>
                {leadQuizData?.data?.venue ?? ''}
              </Descriptions.Item>
              <Descriptions.Item label={'Budget'}>
                {leadQuizData?.data?.budget?.toString() ?? ''}
              </Descriptions.Item>
              <Descriptions.Item label={'Event'}>
                {leadQuizData?.data?.event?.toString() ?? ''}
              </Descriptions.Item>
              <Descriptions.Item label={'Venue Decided'}>
                {leadQuizData?.data?.venue_decided ? (
                  <Icons.CheckCircleTwoTone twoToneColor={'#52c41a'} />
                ) : (
                  <Icons.CloseCircleTwoTone twoToneColor={'red'} />
                )}
              </Descriptions.Item>
              <Descriptions.Item label={'Service'}>
                {leadQuizData?.data?.service?.toString() ?? ''}
              </Descriptions.Item>
              <Descriptions.Item label={'Event Date'}>
                {leadQuizData?.data?.event_date?.toString() ?? ''}
              </Descriptions.Item>
              <Descriptions.Item label={'Venue Type'}>
                {leadQuizData?.data?.venue_type?.toString() ?? ''}
              </Descriptions.Item>
              <Descriptions.Item label={'PAX'}>
                {leadQuizData?.data?.pax?.toString() ?? ''}
              </Descriptions.Item>
              <Descriptions.Item label={'Area'}>
                {leadQuizData?.data?.area?.toString() ?? ''}
              </Descriptions.Item>
            </Descriptions>
            <>
              {!!leadQuizData?.data.quiz_package_details?.length && (
                <Descriptions.Item label={'Guest Count'}>
                  <QuizPackageDetails
                    packageDetail={
                      leadQuizData?.data?.decor_options?.toString() ?? ''
                    }
                    quizPackageDetail={leadQuizData?.data.quiz_package_details}
                  />
                </Descriptions.Item>
              )}
            </>
            <>
              {!!leadQuizData?.data.json_data &&
                Object.keys(leadQuizData?.data.json_data).length > 0 && (
                  <QuizDisplay
                    data={
                      Array.isArray(leadQuizData?.data.json_data)
                        ? leadQuizData?.data.json_data
                        : JSON.parse(leadQuizData?.data.json_data)
                    }
                  />
                )}
            </>
          </>
        )}
      </Modal>
    </>
  )
}

interface QuizDisplayProps {
  data: QuestionProps[]
}

const QuizDisplay: React.FC<QuizDisplayProps> = ({ data }) => {
  console.log(data)
  return (
    <div>
      {data.map((item, index) => (
        <div key={index} style={{ marginBottom: '20px' }}>
          <h3>{item.question}</h3>
          {item.type === 'multiQuestion' ? (
            item.answer.map((subAnswer: string[], repeatIndex: number) => (
              <div key={repeatIndex} style={{ marginLeft: '20px' }}>
                <h4>Day {repeatIndex + 1}</h4>
                {subAnswer.map((subAns: string, subIndex: number) => (
                  <p key={subIndex}>
                    {item.subQuestions?.[subIndex].question}: {subAns}
                  </p>
                ))}
              </div>
            ))
          ) : Array.isArray(item.answer) ? (
            item.answer.map((ans: string, ansIndex: number) => (
              <p key={ansIndex}>
                {item.options?.find((opt) => opt.value === ans)?.title || ans}
              </p>
            ))
          ) : (
            <p>
              {item.options?.find((opt) => opt.value === item.answer)?.title ||
                item.answer}
            </p>
          )}
          {/* {
            Array.isArray(item.answer) ? (
              item.answer.map((ans: string, ansIndex: number) => (
                <p key={ansIndex}>{item.options?.find(opt => opt.value === ans)?.title || ans}</p>
              ))
            ) : (
              <p>{item.answer}</p>
            )
          } */}
        </div>
      ))}
    </div>
  )
}
